<template>
  <div class="w1092">
    <div class="pagenamew">
      <span class="couponn">{{$t('front.message.allMsg')}}<span class="pkc">{{pageInfo.tatal_list_count}}</span>{{$t('front.message.num')}}</span>
      <span class="coutxt">{{$t('front.message.maxMsg')}} <span class="blc">14</span>{{$t('front.message.max')}} <span class="blc">20</span>{{$t('front.message.numMsg')}}</span>
    </div>

    <div class="board">
      <ul class="boardw first">
        <li class="w95">{{$t('front.board.checks')}}</li>
        <li class="w95">{{$t('front.board.check')}}</li>
        <li class="w600">{{$t('front.board.title')}}</li>
        <li class="w150">{{$t('front.board.sendTime')}}</li>
        <li class="w95">{{$t('front.board.delete')}}</li>
      </ul>

      <template v-if="list && list.length > 0">
        <template v-for="item in list" v-bind:key="item.msgIdx">
          <ul class="boardw">
            <li class="w95"><input type="checkbox" v-model="item.checked"></li>
            <li class="w95">
              <span class="ocmsg" :class="{'closemsg': item.msgStatus == 'N', 'openmsg': item.msgStatus != 'N'}"></span>
            </li>
            <li class="w600 textleft">
              <a @click="onMessageClick(item)">
                <span class="nicon iblbg mr10">{{$t('front.boardCategory.notice')}}</span>
                {{item.msgTitle}}
                <span class="newicon rdbg ml10">N</span>
              </a>
            </li>
            <li class="w150">{{dateFormat(item.sendDate)}}</li>
            <li class="w95"><span class="nbicon rdbg" @click="onRemoveOne(item)">{{$t('front.board.delete')}}</span></li>
          </ul>
        </template>
      </template>

<!--      <ul class="boardw wtblbg">-->
<!--        <li class="w95"><input type="checkbox"></li>-->
<!--        <li class="w95"><span class="ocmsg closemsg"></span></li>-->
<!--        <li class="w600 textleft"><a @click="onMessageClick"><span class="nicon iblbg mr10">안내</span>은행계좌정보<span class="newicon rdbg ml10">N</span></a></li>-->
<!--        <li class="w150">2021.06.29 15:36</li>-->
<!--        <li class="w95"><span class="nbicon bgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w95"><input type="checkbox"></li>-->
<!--        <li class="w95"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w600 textleft"><a @click="onMessageClick">은행계좌정보<span class="newicon ml10">N</span></a></li>-->
<!--        <li class="w150">2021.06.29 15:36</li>-->
<!--        <li class="w95"><span class="nbicon grbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w95"><input type="checkbox"></li>-->
<!--        <li class="w95"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w600 textleft"><a>은행계좌정보<span class="newicon ml10">N</span></a></li>-->
<!--        <li class="w150">2021.06.29 15:36</li>-->
<!--        <li class="w95"><span class="nbicon grbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw wtblbg">-->
<!--        <li class="w95"><input type="checkbox"></li>-->
<!--        <li class="w95"><span class="ocmsg closemsg"></span></li>-->
<!--        <li class="w600 textleft"><a><span class="nicon iywbg mr10">이벤트</span>은행계좌정보<span class="newicon ml10">N</span></a></li>-->
<!--        <li class="w150">2021.06.29 15:36</li>-->
<!--        <li class="w95"><span class="nbicon bgrbg">삭제</span></li>-->
<!--      </ul>-->
<!--      <ul class="boardw">-->
<!--        <li class="w95"><input type="checkbox"></li>-->
<!--        <li class="w95"><span class="ocmsg openmsg"></span></li>-->
<!--        <li class="w600 textleft"><a>은행계좌정보<span class="newicon ml10">N</span></a></li>-->
<!--        <li class="w150">2021.06.29 15:36</li>-->
<!--        <li class="w95"><span class="nbicon grbg">삭제</span></li>-->
<!--      </ul>-->
    </div>
    <div class="boardbtn">
      <a class="grsbtn left mr5" @click="onAllCheck">{{ $t('front.board.AllSelect') }}</a>
      <a class="grsbtn left" @click="onRemoveMsg">{{ $t('front.board.AllDelete') }}</a>
      <a class="grsbtn right" @click="onAllRead">{{ $t('front.board.AllRead') }}</a>
    </div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList"
    />

    <board-filter  @onSearch="onSearch"/>

  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'
import { getMsgList, setMsgRead, setMsgUpdate } from '@/api/board'
export default {
  name: 'messageList',
  components: { BoardFilter, Pagination },
  data () {
    return {
      list: [],
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: []
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text
      this.loadList()
    },
    loadList (page) {
      const params = {
        page: page || this.pageInfo.page
      }
      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }
      getMsgList(params).then(response => {
        const result = response.data

        if (result.resultCode === '0') {
          const list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }

          for (let i = 0, iLen = list.length; i < iLen; i++) {
            const item = list[i]
            item.checked = false
          }
          this.list = list
        }
      })
    },
    onMessageClick (item, isMobile) {
      item.searchParam = JSON.stringify(this.searchParam)
      if (isMobile) {
        location.href = '/msgRead/' + item.msgIdx
      } else {
        // this.goPageByName('messageRead', item)
        location.href = '/mypage/message/read/' + item.msgIdx
      }
    },
    onAllCheck (list) {
      this.allChecked = !this.allChecked
      this.list.forEach(item => {
        item.checked = this.allChecked
      })
    },
    async onRemoveOne (item) {
      const confirm = await this.onConfirm('front.message.confirmDeleteMessageMultiple')
      if (confirm) {
        const params = {
          msgIdx: item.msgIdx,
          recieveId: item.recieveId,
          status: 'N'
        }
        await setMsgUpdate(params).then(response => {
          this.onCheck('front.message.completeDeleteMessage')
          this.loadList()
        })
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      let unreadCount = 0
      this.list.forEach(item => {
        if (item.checked) {
          checkedCount++

          if (item.msgStatus === 'N') {
            unreadCount++
          }
        }
      })

      if (unreadCount > 0) {
        this.onCheck('front.message.cannotDeleteMessage')
        return false
      }

      if (checkedCount <= 0) {
        this.onCheck('front.message.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.message.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.list.length; i < iLen; i++) {
          const item = this.list[i]
          if (item.checked) {
            const params = {
              msgIdx: item.msgIdx,
              recieveId: item.recieveId
            }
            setMsgUpdate(params).then(response => {
            })
          }
        }
        await this.onCheck('front.message.completeDeleteMessage')
        this.loadList()
      }
    },
    async onAllRead () {
      const confirm = await this.onConfirm('front.message.confirmAllRead')

      if (confirm) {
        const params = {
        }
        setMsgRead(params).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.loadList()
          }
        })
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
